(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('PaymentSettingsController', PaymentSettingsController);

    //** NgInject */
    function PaymentSettingsController(toastr, PaymentSettings, StoreService, PaymentDiscountService, $uibModal, $localStorage, $translate,
        $location, $timeout, ModalFactory, Principal, $window, $scope, PaymentSetupService, PagarMeService) {

		var vm = this;
        var userId = $localStorage.userId;
        var storeAccountId = undefined;
		vm.linksWirecard = {};
        vm.store = $localStorage.defaultStore;
        vm.linksWirecard = {};
        vm.moipInfo = {};
        vm.picPaySettings = {};
        vm.pagarmeBalance = {};
        vm.tBanksSettings = {};
        vm.isSaving = false;
        vm.pagarme = {};
        vm.pagarme.bankAccount = {};
        vm.pagarme.registerInformation = {};
        vm.pagarme.registerInformation.address = {};
        vm.pagarme.registerInformation.managingPartners = [];
        vm.pagarme.registerInformation.type = 'individual';
        vm.pagarme.bankAccount.holderType = 'individual';
        vm.pagarme.transferEnable = true;
        vm.pagarme.transferInterval = 'MONTHLY'
        vm.pagarme.transferDay = 1;
        vm.checkoutMethod = 'NONE';
        vm.transferDays = []
        vm.banks = getBanksBrasil();
        vm.installment = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        vm.openModalDisconnectWirecard = openModalDisconnectWirecard;
        vm.openModalDisconnectPagarMe = openModalDisconnectPagarMe;
        vm.tBanksConfirmationToken = { tokenCode: "" };
        vm.transferDaysTest = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
        vm.transferDays = [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' },
            { value: 9, label: '9' },
            { value: 10, label: '10' },
            { value: 11, label: '11' },
            { value: 12, label: '12' },
            { value: 13, label: '13' },
            { value: 14, label: '14' },
            { value: 15, label: '15' },
            { value: 16, label: '16' },
            { value: 17, label: '17' },
            { value: 18, label: '18' },
            { value: 19, label: '19' },
            { value: 20, label: '20' },
            { value: 21, label: '21' },
            { value: 22, label: '22' },
            { value: 23, label: '23' },
            { value: 24, label: '24' },
            { value: 25, label: '25' },
            { value: 26, label: '26' },
            { value: 27, label: '27' },
            { value: 28, label: '28' },
            { value: 29, label: '29' },
            { value: 30, label: '30' },
            { value: 31, label: '31' },
        ];
        vm.paymentSettings = {
            'toBeAgreed': false,
            'redirectToSellerWhatsapp': false
        };
        vm.haveIntegrationWithPayments = false;
        vm.hasPicPayPayment = false;
        vm.hasToBeAgreedPayment = false;
        vm.hasWirecardPayment = false;
        vm.hasPagarmePayment = false;
        vm.isCreditCardEnabled = false;
        vm.isBoletoEnabled = false;
        vm.hasTBanksPayment = false;
        vm.pixPaymentDiscountList = [];
        vm.currencyPrefix = getCurrencyListConfiguration()['REAL'];
        vm.currency = 'REAL';
        vm.occupations = PaymentSetupService.getProfessionalOccupations();
        vm.pagarMeIntegrationType = 'SHOWKASE_PAY';

        init();

        function init() {
            loadStoreData();
            loadAll();
            if ($location.search().code) {
                PaymentSettings.setCodeAndSaveAccessToken($location.search().code || " ").then(function  (response) {
                    $timeout(function  () {
                        toastr.success($translate.instant('settings.shipping.successConnected'));
                    }, 500);

                    if (response.data) {
                        vm.moipInfo = response.data;
                    }

                    $location.url($location.path());
                }, function  (response) {
                    toastr.error($translate.instant('settings.shipping.failureToConnect'));
                    $location.url($location.path());
                });
            }
            loginWithPagarMeHubCallback();
        }

        function loginWithPagarMeHubCallback() {
            vm.loading = true;
            var authCode = $location.search().authorization_code;
            if (authCode) {
                PagarMeService.authenticate($localStorage.defaultStore.storeId, authCode).then(
                    function(response) {
                        toastr.success($translate.instant('settings.store.payments.tabs.pagarMe.successConnected'));
                        loadAll();
                        vm.loading = false;
                    },
                    function(error) { 
                        vm.loading = false; 
                        toastr.error($translate.instant('settings.store.payments.tabs.pagarMe.failureToConnect'));
                    }
                );
            }
        }

        function loadAll() {
            loadSetup();
        }

        function loadStoreData() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                if (response){
                    loadCurrencyPrefix(response.data);
                    storeAccountId = response.data.ownerId;
                }
            })
        }

        function loadCurrencyPrefix(store) {
            var key = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            vm.currencyPrefix = config.prefix;
            vm.currency = key;
        }

        function loadPicPay(picPayData) {
            if (picPayData) {
                vm.picPaySettings = picPayData;
                vm.haveIntegrationWithPayments = true;
                vm.hasPicPayPayment = !isNullEmptyOrUndefined(vm.picPaySettings.picPayToken)
                    && !isNullEmptyOrUndefined(vm.picPaySettings.sellerToken)
                    && !isNullOrUndefined(vm.picPaySettings.id);
            }
        }

        function loadPaymentSettings(settingsData) {
            if (settingsData) {
                vm.paymentSettings = settingsData;
                vm.haveIntegrationWithPayments = vm.paymentSettings.toBeAgreed
                vm.hasToBeAgreedPayment = vm.paymentSettings.toBeAgreed;
            }
        }

        function loadPagarMeRecipient(pagarMeData) {
            if (pagarMeData && pagarMeData.recipient) {
                vm.pagarme = pagarMeData.recipient;
                vm.pagarmeBalance = pagarMeData.balance;
                vm.pagarmeEncryptToken = pagarMeData.encryptToken;
                vm.hasPagarmePayment = true;
                // vm.isCreditCardEnabled = vm.pagarme.isCreditCardEnabled;
                // vm.isBoletoEnabled = vm.pagarme.isBoletoEnabled;
                vm.keyCheckoutMethod = 'PAGAR_ME';
                vm.checkoutMethod = 'PAGAR_ME';
            } else {
                vm.pagarme.registerInformation.type = 'individual';
                vm.pagarme.bankAccount.holderType = 'individual';
                vm.pagarme.transferDay = 1;
            }
        }

        function loadPagarMeAccount(pagarMeAccount) {
            if (pagarMeAccount) {
                vm.pagarMeAccount = pagarMeAccount;
                vm.isCreditCardEnabled = vm.pagarMeAccount.isCreditCardEnabled;
                vm.isBoletoEnabled = vm.pagarMeAccount.isBoletoEnabled;
                vm.pagarMeIntegrationType = vm.pagarMeAccount.apiVersion;
            } else {
                vm.pagarMeAccount = {
                    apiVersion: 'SHOWKASE_PAY'
                }
            }
        }

        function loadInstallments(installmentData) {
            vm.installments = installmentData;
        }

        function loadTBanks(tbanksData) {
            if (tbanksData) {
                vm.tBanksSettings = tbanksData;
                if (!vm.haveIntegrationWithPayments) {
                    vm.haveIntegrationWithPayments = vm.tBanksSettings.status || vm.tBanksSettings.status != 'Rejeitada' || vm.tBanksSettings.status != 'Criada';
                }

                if (!vm.hasTBanksPayment) {
                    vm.hasTBanksPayment = vm.tBanksSettings.status || vm.tBanksSettings.status != 'Rejeitada' || vm.tBanksSettings.status != 'Criada';
                }
            } else {
                vm.hasTBanksPayment = false;
                StoreService.getStore($localStorage.defaultStore.storeId).then(function (response) {
                    if (response) {
                        vm.tBanksSettings.companyName = response.data.name;
                        vm.tBanksSettings.companyDocument = response.data.cnpj;
                    }
                });
            }
        }

        function loadPaymentDiscount(paymentConditionDiscountData) {
            if (paymentConditionDiscountData && paymentConditionDiscountData.length > 0) {
                vm.pixPaymentDiscountList = paymentConditionDiscountData.filter(function(item) { return item.paymentCondition == "PIX" });
            } else {
                vm.pixPaymentDiscountList = [];
            }
        }

        function loadSetup() {
            PaymentSetupService.getByStoreId($localStorage.defaultStore.storeId).then(function(response) {
                if (response && response.data) {
                    loadPicPay(response.data.picPayData);
                    loadPaymentSettings(response.data.settingsData);
                    loadPagarMeRecipient(response.data.pagarMeData);
                    loadPagarMeAccount(response.data.pagarMeAccountData);
                    loadInstallments(response.data.installmentData);
                    loadTBanks(response.data.tbanksData);
                    loadPaymentDiscount(response.data.paymentConditionDiscountData);
                }
            });
        }

        vm.authorizePagarMe = function() {
            vm.loading = true;
            PagarMeService.authorize($localStorage.defaultStore.storeId).then(
                function(response) {},
                function(){ vm.loading = false; }
            );
        }

        vm.updateBankAccountType = function() {
            if (vm.pagarme.registerInformation.type === 'corporation') {
                vm.pagarme.bankAccount.holderType = 'COMPANY';
            } else {
                vm.pagarme.bankAccount.holderType = 'INDIVIDUAL';
            }
        }

        vm.updateRedirectToSellerHandler = function () {
            vm.loading = true;
            StoreService.updateRedirectToSellerValue(vm.paymentSettings.id, vm.paymentSettings.redirectToSellerWhatsapp)
            .then(function(response) {
                vm.loading = false
                if (response) {
                    toastr.success($translate.instant('settings.clique-retire.success'));
                } else {
                    toastr.success($translate.instant('settings.clique-retire.fail'));
                    vm.paymentSettings.redirectToSellerWhatsapp = !vm.paymentSettings.redirectToSellerWhatsapp;
                }
            })
        }

        vm.updateToBeAgreedHandler = function () {
            var creditCard = vm.pagarme && vm.pagarme.isCreditCardEnabled;
            var boleto = vm.pagarme && vm.pagarme.isBoletoEnabled;
            var picpay = vm.picPaySettings && vm.picPaySettings.isEnabled;
            var pix = vm.tBanksSettings && vm.tBanksSettings.isEnabled;
            var toBeAgreed = vm.paymentSettings && vm.paymentSettings.toBeAgreed;
            var activePayments = [creditCard, boleto, picpay, pix, toBeAgreed].filter(function(x) { return x; }).length;
            if (activePayments == 0) {
                toastr.error($translate.instant('settings.store.payments.tabs.toBeAgreed.onlyPaymentForm'));
                vm.paymentSettings.toBeAgreed = !vm.paymentSettings.toBeAgreed;
                return;
            }

            vm.loading = true;
            StoreService.updateToBeAgreedValue(vm.paymentSettings.id, vm.paymentSettings.toBeAgreed).then(function(response) {
                vm.loading = false
                if (response) {
                    toastr.success($translate.instant('settings.clique-retire.success'));
                } else {
                    toastr.success($translate.instant('settings.clique-retire.fail'));
                    vm.paymentSettings.toBeAgreed = !vm.paymentSettings.toBeAgreed;
                }
                vm.hasToBeAgreedPayment = vm.paymentSettings.toBeAgreed;
            })
        }

        vm.savePicPaySettings = function savePicPaySettings() {
            vm.picPaySettings.storeId = $localStorage.defaultStore.storeId;
            StoreService.savePicPayConfiguration(vm.picPaySettings).then(function  (response) {
                toastr.success($translate.instant('settings.store.messages.success'));
                loadSetup();
            }, function (data) {
                toastr.error($translate.instant('settings.store.messages.error.fail'));
                vm.hasPicPayPayment = false;
            });

        }

        vm.saveTBanksSettings = function saveTBanksSettings() {
            if (vm.hasTBanksPayment
                && !vm.hasToBeAgreedPayment
                && !vm.hasPicPayPayment
                && !vm.hasWirecardPayment
                && !vm.hasPagarmePayment
            ) {
                toastr.error($translate.instant('settings.store.payments.tabs.pix.onlyPaymentForm'));
                return;
            }

            vm.tBanksSettings.storeId = vm.store.storeId;
            vm.isSaving = true;
            StoreService.saveTBanksConfiguration(vm.tBanksSettings).then(function  (response) {
                if (response) {
                    vm.tBanksSettings = response.data;
                }
                vm.isSaving = false;
                vm.hasTBanksPayment = true;
                toastr.success($translate.instant('settings.store.payments.tabs.pix.successConnected'));
                window.scrollTo({ top: 0, behavior: 'smooth' });
                loadSetup();
            }, function (error) {
                vm.isSaving = false;
                vm.hasTBanksPayment = false;
                if (error && error.data && error.data.headers.statuskey){
                    toastr.error($translate.instant('settings.store.payments.tabs.pix.' + error.data.headers.statuskey));
                } else {
                    toastr.error($translate.instant('settings.store.payments.tabs.pix.failureToConnect'));
                }
            });
        }

        vm.verifyTBanksConfirmationToken = function verifyTBanksConfirmationToken() {
            vm.isSaving = true;
            StoreService.verifyTBanksConfirmationToken(vm.tBanksConfirmationToken).then(function () {
                vm.isSaving = false;
                toastr.success($translate.instant('settings.store.payments.tabs.pix.successToVerifyToken'));
                loadSetup();
            }, function (error) {
                vm.isSaving = false;
                toastr.error($translate.instant('settings.store.payments.tabs.pix.failureToVerifyToken'));
            });
        }

        vm.resendTBanksConfirmationToken = function resendTBanksConfirmationToken() {
            vm.isSaving = true;
            StoreService.resendTBanksConfirmationToken().then(function () {
                vm.isSaving = false;
                toastr.success($translate.instant('settings.store.payments.tabs.pix.successToResendToken'));
            }, function (data) {
                vm.isSaving = false;
                toastr.error($translate.instant('settings.store.payments.tabs.pix.failureToResendToken'));
            });
        }

        vm.openPaymentDiscountFormModal = function (paymentCondition, paymentDiscount) {
            vm.paymentDiscountFormModal = $uibModal.open({
                templateUrl: 'app/admin/settings/payments/component/payment-discount-form-modal.html',
                controller: 'PaymentDiscountFormModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    paymentCondition: function () {
                        return paymentCondition;
                    },
                    paymentDiscount: function () {
                        return paymentDiscount;
                    },
                    currency: function () {
                        return vm.currency;
                    }
                },
            }).result.then(function (response) {
                loadSetup();
            });
        }

        vm.activateDeactivatePayment = function(id, paymentCondition, paymentDiscount) {
            var creditCard = vm.pagarme && vm.pagarme.isCreditCardEnabled;
            var boleto = vm.pagarme && vm.pagarme.isBoletoEnabled;
            var picpay = vm.picPaySettings && vm.picPaySettings.isEnabled;
            var pix = vm.tBanksSettings && vm.tBanksSettings.isEnabled;
            var toBeAgreed = vm.paymentSettings && vm.paymentSettings.toBeAgreed;
            var activePayments = [creditCard, boleto, picpay, pix, toBeAgreed].filter(function(x) { return x; }).length;
            if (activePayments == 0) {
                toastr.error($translate.instant('settings.store.payments.messages.activate-deactivate-one-payment-error'));
                loadSetup();
                return;
            }

            var dto = {
                paymentCondition: paymentCondition,
                enabled: paymentDiscount.isEnabled,
                boletoEnabled: paymentDiscount.isBoletoEnabled,
                creditCardEnabled: paymentDiscount.isCreditCardEnabled
            }

            PaymentSetupService.activateDeactivate(id, vm.store.storeId, dto)
                .then(
                    function(response) {
                        processActivateDeactivateResponse(true, dto);
                        loadSetup();
                    },
                    function(err) {
                        processActivateDeactivateResponse(false, dto);
                        console.log(err);
                    }
                );
        }

        vm.disablePaymentDiscount = function(paymentDiscount) {
            PaymentDiscountService.update(paymentDiscount.id, paymentDiscount)
                .then(
                    function(response) {
                        if (paymentDiscount.isEnabled) {
                            toastr.success($translate.instant('settings.store.payments.payment-discount.messages.activate-success'));
                        } else {
                            toastr.success($translate.instant('settings.store.payments.payment-discount.messages.deactivate-success'));
                        }
                        loadSetup();
                    },
                    function(err) {
                        toastr.error($translate.instant('settings.store.payments.payment-discount.messages.activate-deactivate-success'));
                    }
                );
        }

        vm.deletePaymentDiscount = function(paymentDiscount) {
            PaymentDiscountService.remove(paymentDiscount.id)
                .then(
                    function(response) {
                        toastr.success($translate.instant('settings.store.payments.payment-discount.messages.delete-success'));
                        loadSetup();
                    },
                    function(err) {
                        toastr.error($translate.instant('settings.store.payments.payment-discount.messages.delete-error'));
                    }
                );
        }

        vm.copiedToClipboard = function() {
            toastr.success($translate.instant('settings.erps.bling.instructions.copied'));
        }

        function actualyDisconnect() { // REMOVER
			Principal.disconnectFromPaymentGatewayWirecard().then(function(response) {
				if (response.data == 'OK') {
					vm.moipInfo = {};
				}
			});
		}

        PaymentSettings.getGatewayWirecardPaymentLinks().then(function (links) {
            if (links) {
                vm.linksWirecard = links;
            }
        });

        function openModalDisconnectWirecard() {
            ModalFactory.openSimpleModal(
                $translate.instant('settings.store.payments.tabs.wirecard.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.tabs.wirecard.sureDisconnectQuestion'),
                $translate.instant('settings.store.payments.tabs.wirecard.yes'),
                $translate.instant('settings.store.payments.tabs.wirecard.no'),
                disconnectWirecard);
        }

        function openModalDisconnectPagarMe() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.store.payments.tabs.pagarMe.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.tabs.pagarMe.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.store.payments.tabs.pagarMe.yes'),
                $translate.instant('settings.store.payments.tabs.pagarMe.no'),
                disconnectPagarme
            );
        }

        function openModalChangeToPagarMe() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.store.payments.changePayment.toPagarMe.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.changePayment.toPagarMe.message'),
                null,
                $translate.instant('settings.store.payments.changePayment.toPagarMe.yes'),
                $translate.instant('settings.store.payments.changePayment.toPagarMe.no'),
                disconnectWiredAndSaveMethodPagarMe
            );
        }

        function openModalChangeToWirecard() {
            ModalFactory.openSimpleModal(
                $translate.instant('settings.store.payments.changePayment.toWirecard.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.changePayment.toWirecard.message'),
                $translate.instant('settings.store.payments.changePayment.toWirecard.yes'),
                $translate.instant('settings.store.payments.changePayment.toWirecard.no'),
                disconnectPagarMeAndSaveMethodWired);
        }

        function disconnectWirecard() {
            if (vm.hasWirecardPayment
                && !vm.hasPagarmePayment
                && !vm.hasToBeAgreedPayment
                && !vm.hasPicPayPayment
                && !vm.hasTBanksPayment
            ) {
                toastr.error($translate.instant('settings.store.payments.tabs.wirecard.onlyPaymentForm'));
                return;
            }

            Principal.disconnectFromPaymentGatewayWirecard().then(function (response) {
                if (response.data == 'OK') {
                    vm.moipInfo = {};
                    vm.hasWirecardPayment = false;
                }
            });
        }

        function disconnectPagarme() {
            if (vm.hasPagarmePayment
                && !vm.hasWirecardPayment
                && !vm.hasToBeAgreedPayment
                && !vm.hasPicPayPayment
                && !vm.hasTBanksPayment
            ) {
                toastr.error($translate.instant('settings.store.payments.tabs.pagarme.onlyPaymentForm'));
                return;
            }

            PaymentSettings.setCheckoutMethod(userId, 'NONE').then(function (response) {
                vm.checkoutMethod = 'NONE';
                vm.keyCheckoutMethod = 'NONE';
                vm.pagarme = {};
                vm.hasPagarmePayment = false;
                vm.isCreditCardEnabled = false;
                vm.isBoletoEnabled = false;
            });
        }

        function hasMoipAccount() {
            return (Object.keys(vm.moipInfo).length !== 0);
        }


        vm.addInstallment = function () {
            vm.installments.push({
              allowInstallment: true,
              installment: undefined,
              installmentNoFee: undefined,
              minimumValue: undefined,
              storeId: vm.store.storeId
            });
        }

        vm.removeInstallment = function (index) {
            if (vm.installments.length > 1) {
              vm.installments.splice(index, 1);
            } else {
              vm.installments = [{ allowInstallment: null, installment: null, installmentNoFee: null, minimumValue: null }];
            }
        }

        vm.saveInstallment = function () {
            PaymentSettings.addInstallmensts(vm.installments).then(
              function (response) {
                vm.isSaving = false;
                vm.error = null;
                vm.success = 'OK';
                processResponse('settings.store.payments.tabs.installment.messages.update');
              },
              function (err) {
                vm.isSaving = false;
                vm.success = null;
                vm.error = err;
                processResponse('settings.store.payments.tabs.installment.messages.fail');
              }
            );
        };

        function processResponse(message) {
            if (vm.error) {
              toastr.error($translate.instant(message));
              vm.error = undefined;
            } else if (vm.success && vm.success == 'OK') {
              vm.success = undefined;
              toastr.success($translate.instant(message));
            }
        }

        vm.saveMethodPagarMe = function () {
            if (vm.pagarmeForm.$invalid) {
                toastr.warning("Verifique os campos invalidos")
                angular.forEach(vm.pagarmeForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                return;
            }

            if (hasMoipAccount()) {
                openModalChangeToPagarMe();
            } else {
                saveOrEditMethodPagarMe()
            }
        };

        vm.connecttWirecard = function () {
            if (vm.keyCheckoutMethod === "PAGAR_ME") {
                openModalChangeToWirecard();
            } else {
                disconnectPagarMeAndSaveMethodWired();
            }
        }

        vm.copiedToClipboard = function() {
            toastr.success($translate.instant('settings.store.payments.tabs.pix.copied'));
        }

        vm.getPixKey = function() {
            return vm.tBanksSettings.transactionKey;
        }

        vm.openDisconnectPicPayModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.store.payments.tabs.picpay.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.tabs.picpay.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.store.payments.tabs.picpay.yes'),
                $translate.instant('settings.store.payments.tabs.picpay.no'),
                disconnectPicPay
            );
        }

        function disconnectPicPay() {
            if (vm.hasPicPayPayment
                && !vm.hasToBeAgreedPayment
                && !vm.hasWirecardPayment
                && !vm.hasPagarmePayment
                && !vm.hasTBanksPayment
            ) {
                toastr.error($translate.instant('settings.store.payments.tabs.picpay.onlyPaymentForm'));
                return;
            }

            vm.picPaySettings.picPayToken = '';
            vm.picPaySettings.sellerToken = '';

            vm.savePicPaySettings();
        }

        vm.openDisconnectTBanksModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.store.payments.tabs.pix.sureDisconnectTitle'),
                $translate.instant('settings.store.payments.tabs.pix.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.store.payments.tabs.pix.yes'),
                $translate.instant('settings.store.payments.tabs.pix.no'),
                disconnectTBanks
            );
        }

        function disconnectTBanks() {
            PaymentSetupService.disconnect(vm.tBanksSettings.id, $localStorage.defaultStore.storeId, 'T_BANKS').then(
                function (response) {
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.store.payments.tabs.pix.successDisconnectMessage');
                },
                function (err) {
                    vm.success = null;
                    vm.error = err;
                    processResponse('settings.store.payments.tabs.pix.errorDisconnectMessage');
                }
              );
        }

        function disconnectWiredAndSaveMethodPagarMe() {
            Principal.disconnectFromPaymentGatewayWirecard().then(function (response) {
                if (response.data == 'OK') {
                    vm.moipInfo = {};
                    saveOrEditMethodPagarMe();
                }
            });
        }

        function disconnectPagarMeAndSaveMethodWired() {
            disconnectPagarme();
            $window.open(vm.linksWirecard.connectAccountLink, '_blank');
        }

        function saveOrEditMethodPagarMe() {
            vm.pagarme.transferEnabled = true;
            vm.pagarme.bankAccount.holderDocument = vm.pagarme.registerInformation.documentNumber;

            if (vm.pagarme.id && vm.pagarme.recipientId) {
                PagarMeService.updateRecipient(vm.pagarme.id, vm.pagarme).then(function (response) {
                    vm.pagarme = response.data;
                    vm.hasPagarmePayment = true;
                    // vm.isCreditCardEnabled = vm.pagarme.isCreditCardEnabled;
                    // vm.isBoletoEnabled = vm.pagarme.isBoletoEnabled;
                    toastr.success("Dados salvos com sucesso");
                }).catch(function (response) {
                    vm.hasPagarmePayment = false;
                    vm.isCreditCardEnabled = false;
                    vm.isBoletoEnabled = false;
                    toastr.error("Verifique os campos e tente novamente");
                });
            } else {
                PagarMeService.createRecipient(storeAccountId, vm.pagarme).then(function (response) {
                    vm.pagarme = response.data;
                    vm.hasPagarmePayment = true;
                    // vm.isCreditCardEnabled = vm.pagarme.isCreditCardEnabled;
                    // vm.isBoletoEnabled = vm.pagarme.isBoletoEnabled;
                    vm.checkoutMethod = "PAGAR_ME";
                    vm.keyCheckoutMethod = "PAGAR_ME";
                    toastr.success("Dados salvos com sucesso");
                }).catch(function (response) {
                    toastr.error("Verifique os campos e tente novamente");
                });
            }
        }

        function processActivateDeactivateResponse(isSuccess, activateDeactivate) {
            var messageId = undefined;
            switch (activateDeactivate.paymentCondition) {
                case 'PIX':
                    if (!isSuccess) {
                        messageId = 'settings.store.payments.tabs.pix.activated-deactivated-error';
                        break;
                    }

                    if (activateDeactivate.enabled) {
                        messageId = 'settings.store.payments.tabs.pix.activated-success';
                    } else {
                        messageId = 'settings.store.payments.tabs.pix.deactivated-success';
                    }
                    break;
                case 'CREDIT_CARD':
                    if (!isSuccess) {
                        messageId = 'settings.store.payments.tabs.pagarMe.activated-deactivated-error-credit-card';
                        break;
                    }

                    if (activateDeactivate.creditCardEnabled) {
                        messageId = 'settings.store.payments.tabs.pagarMe.activated-success-credit-card';
                    } else {
                        messageId = 'settings.store.payments.tabs.pagarMe.deactivated-success-credit-card';
                    }
                    break;
                case 'BOLETO':
                    if (!isSuccess) {
                        messageId = 'settings.store.payments.tabs.pagarMe.activated-deactivated-error-boleto';
                        break;
                    }

                    if (activateDeactivate.boletoEnabled) {
                        messageId = 'settings.store.payments.tabs.pagarMe.activated-success-boleto';
                    } else {
                        messageId = 'settings.store.payments.tabs.pagarMe.deactivated-success-boleto';
                    }
                    break;
                case 'PIC_PAY':
                    if (!isSuccess) {
                        messageId = 'settings.store.payments.tabs.pagarMe.activated-deactivated-error';
                        break;
                    }

                    if (activateDeactivate.enabled) {
                        messageId = 'settings.store.payments.tabs.picpay.activated-success';
                    } else {
                        messageId = 'settings.store.payments.tabs.picpay.deactivated-success';
                    }
                    break;
            }

            if (!messageId) {
                return;
            }

            if (isSuccess) {
                toastr.success($translate.instant(messageId));
            } else {
                toastr.error($translate.instant(messageId));
            }
        }

        vm.disabledCnpj = function () {
            return (vm.pagarme.id !== undefined && vm.pagarme.registerInformation.type.toLowerCase() === 'individual');
        }

        vm.disabledCpf = function () {
            return (vm.pagarme.id !== undefined && vm.pagarme.registerInformation.type.toLowerCase() === 'corporation');
        }

        vm.changeMethodPaymant = function (method) {
            vm.checkoutMethod = method;
        }

        vm.removeManagingPartner = function (partnerIndex, partner) {
            if (vm.pagarme && vm.pagarme.registerInformation && vm.pagarme.registerInformation.managingPartners) {
                vm.pagarme.registerInformation.managingPartners = vm.pagarme.registerInformation.managingPartners
                    .filter(function(item, index) { return partnerIndex !== index });
            }
        }

        vm.updateManagingPartner = function (partnerIndex, partner) {
            vm.managingPartnerModal = $uibModal.open({
                templateUrl: 'app/admin/settings/payments/pagarme/modal/add-managing-partner-modal.html',
                controller: 'AddManagingPartnerController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    partner: function () {
                        return partner;
                    },
                    partnerIndex: function() {
                        return partnerIndex;
                    }
                },
            }).result.then(function (response) {
                if (response.data) {
                    vm.pagarme.registerInformation.managingPartners[response.data.partnerIndex] = response.data.partner;
                }

            });
        }

        vm.openManagingPartnerModal = function () {
            vm.managingPartnerModal = $uibModal.open({
                templateUrl: 'app/admin/settings/payments/pagarme/modal/add-managing-partner-modal.html',
                controller: 'AddManagingPartnerController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    partner: function () {
                        return undefined;
                    },
                    partnerIndex: function() {
                        return undefined;
                    }
                },
            }).result.then(function (response) {
                if (response.data) {
                    if (!vm.pagarme.registerInformation.managingPartners) {
                        vm.pagarme.registerInformation.managingPartners = [];
                    }

                    vm.pagarme.registerInformation.managingPartners.push(response.data.partner);
                }

            });
        }

        angular.element(function () {
            $timeout(function() {
                initAutocomplete();
            }, 2000);
        });

        function initAutocomplete() {
            // Crie o campo de entrada para o autocomplete
            var input = document.getElementById('autocomplete');
            // Inicialize o autocomplete passando o campo de entrada e configurando os tipos de lugares que deseja sugerir
            var autocomplete = new google.maps.places.Autocomplete(input, {
              types: ['geocode'], // Pode alterar para ['(regions)'] para sugerir áreas mais amplas
              componentRestrictions: { country: 'br' } // Restringe as sugestões ao Brasil
            });

            // Adicione um listener para lidar com o evento de seleção
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();

              if (!place.geometry) {
                // Se o usuário inseriu um lugar que não existe, exiba uma mensagem
                console.log("Nenhuma informação disponível para o local inserido: '" + place.name + "'");
                return;
              }

              // Faça algo com o resultado selecionado
              populateAddressFields(place);
            });
          }


        function populateAddressFields(result) {
            if (!vm.pagarme.registerInformation.address) {
                vm.pagarme.registerInformation.address = {};
            }
            var addressComponents = result.address_components;
            for (var i = 0; i < addressComponents.length; i++) {
              var component = addressComponents[i];
              var types = component.types;
              for (var j = 0; j < types.length; j++) {
                var type = types[j];
                if (type === "route") {
                    vm.pagarme.registerInformation.address.street = component.long_name;
                } else if (type === "sublocality_level_1" || type === "locality") {
                    vm.pagarme.registerInformation.address.neighborhood = component.long_name;
                } else if (type === "administrative_area_level_2") {
                    vm.pagarme.registerInformation.address.city = component.long_name;
                } else if (type === "administrative_area_level_1") {
                    vm.pagarme.registerInformation.address.state = component.short_name;
                } else if (type === "country") {
                    vm.pagarme.registerInformation.address.country = component.short_name;
                } else if (type === "postal_code") {
                    vm.pagarme.registerInformation.address.zipcode = component.long_name;
                } else if (type === "street_number") {
                    vm.pagarme.registerInformation.address.streetNumber = component.long_name;
                }
              }
            }
            $scope.$apply();
          }
    }
})();
