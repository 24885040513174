(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('ErpsSettingsController', ErpsSettingsController);

    //** NgInject */
    function ErpsSettingsController(toastr, $state, $localStorage, $translate, $location, $uibModal, Constants,
                ModalFactory, StoreService, ErpsSettingsService, OnlineRetailService, FlyersService, BlingService, 
                ConfirmActionModalFactory, UserService) {

        var vm = this;

        vm.store = $localStorage.defaultStore;
        vm.currentStore = null;
        vm.loading = false;
        vm.isSaving = false;
        vm.isViewForm = false;
        vm.isMobile = detectar_mobile();

        // Varejo Online
        vm.isLoadingPriceTable = false;
        vm.onlineRetail = null;
        vm.priceTable;
        vm.priceTableList = [];
        vm.promotionalPriceTable;
        vm.promotionalPriceTableList = [{
            "nome": "Nenhum"
        }];
        vm.selectedEntity;
        vm.entities = [];
        vm.entitiesList = [];
        vm.userLinksView = [];
        vm.showkaseUsers = [];
        vm.syncName = false;
        vm.syncDescription = false;
        vm.syncImage = false;
        vm.syncCategory = false;
        vm.syncBrand = false;
        vm.syncTags = false;
        vm.roundPrice = false;
        vm.onlyEcommerce = true;
        vm.inventoryThreshold = false;

        vm.selectedBankAccount = undefined;
        vm.selectedCategoryLevel = undefined;
        vm.bankAccounts = [];
        // Nexaas
        vm.nexaas = null;
        vm.nexaasOrganization = null;
        vm.nexaasOrganizationList = [];
        vm.nexaasStock;
        vm.nexaasStockList = [];
        vm.nexaasSaleChannel;
        vm.nexaasSaleChannelList = [];
        vm.nexaasPriceTable;
        vm.nexaasPriceTableList = [];

        // Bling
        vm.bling = null;

        toastr.options = {
            timeOut: 8000,
        };
        
        init();

        function init() {
            loadStoreData();
            getSoftcomByStore(vm.store.storeId);
            getOnlineRetailByStore(vm.store.storeId);
            getNexaasByStore(vm.store.storeId);
            getBlingByStore(vm.store.storeId);
            processBlingResponse();
        }

        vm.createUpdateOnlineRetail = function () {
            if (vm.onlineRetail.id) {
                ModalFactory.openWarningModal($translate.instant('settings.erps.online-retail.messages.price-modal.title'),
                    $translate.instant('settings.erps.online-retail.messages.price-modal.title'),
                    $translate.instant('settings.erps.online-retail.messages.price-modal.small-text'),
                    null,
                    $translate.instant('settings.erps.online-retail.messages.price-modal.confirm-button'),
                    $translate.instant('settings.erps.online-retail.messages.price-modal.cancel'),
                    updateOnlineRetail
                );
            } else {
                if ((vm.nexaas && vm.nexaas.id) || (vm.bling && vm.bling.id)) {
                    openModalChangePartner(createOnlineRetail);
                } else {
                    createOnlineRetail();
                }
            }
        }

        vm.confirmReprocess = function() {
            ConfirmActionModalFactory.confirmAction().then(function(data) {
                reprocessData()
            });
        }

        vm.getBlingOrderCallbackUrl = function() {
            return $location.protocol() + "://" + $location.host() + "/flyer/api/integration/bling/order/" + vm.store.storeId;
        }

        vm.getBlingStockCallbackUrl = function() {
            return $location.protocol() + "://" + $location.host() + "/flyer/api/integration/bling/stock/" + vm.store.storeId;
        }

        vm.copiedToClipboard = function() {
            toastr.success($translate.instant('settings.erps.bling.instructions.copied'));
        }

        function loadStoreData() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                vm.currentStore = response.data;
            });
        }

        function reprocessData() {
            if (vm.onlineRetail.id) {
                vm.isSaving = true;
                ErpsSettingsService.reprocessOnlineRetail(vm.onlineRetail.id).then(
                    function (response) {
                        vm.isSaving = false;
                        vm.error = null;
                        vm.success = 'OK';
                        vm.onlineRetail.lastIntegrationDate = null;
                        vm.onlineRetail.integrationStartedAt = null;
                        vm.onlineRetail.status = 'NEW';
                        processResponse('settings.erps.online-retail.messages.reprocess');
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        processResponse();
                    }
                );
            } else {
                processResponse();
            }
        }

        function createOnlineRetail() {
            vm.isSaving = true;
            vm.onlineRetail.storeId = vm.store.storeId;
            vm.onlineRetail.priceTableId = vm.priceTable.id;
            vm.onlineRetail.priceTableName = vm.priceTable.nome;
            if (vm.promotionalPriceTable && vm.promotionalPriceTable.id && vm.promotionalPriceTable.nome) {
                vm.onlineRetail.promotionalPriceTableId = vm.promotionalPriceTable.id;
                vm.onlineRetail.promotionalPriceTableName = vm.promotionalPriceTable.nome;
            }
            vm.onlineRetail.partnerId = Constants.ErpPartnerId.VAREJO_ONLINE;
            vm.onlineRetail.entities = vm.selectedEntity && vm.selectedEntity.id ? [vm.selectedEntity.id] : [];
            if (vm.selectedBankAccount && vm.selectedBankAccount.codigo) {
                vm.onlineRetail.accountCode = vm.selectedBankAccount.codigo;
            }
            if (vm.selectedSeller && vm.selectedSeller.id) {
                vm.onlineRetail.sellerCode = vm.selectedSeller.id;
            }
            mapOnlineRetailProductSettings();

            ErpsSettingsService.createERPConfiguration(vm.onlineRetail).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.erps.online-retail.messages.success');

                    $state.reload();
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        function updateOnlineRetail() {
            vm.isSaving = true;
            vm.onlineRetail.storeId = vm.store.storeId;
            vm.onlineRetail.entities = vm.selectedEntity && vm.selectedEntity.id ? [vm.selectedEntity.id] : [];
            vm.onlineRetail.priceTableId = vm.priceTable.id;
            vm.onlineRetail.priceTableName = vm.priceTable.nome;
            if (vm.promotionalPriceTable && vm.promotionalPriceTable.id && vm.promotionalPriceTable.nome) {
                vm.onlineRetail.promotionalPriceTableId = vm.promotionalPriceTable.id;
                vm.onlineRetail.promotionalPriceTableName = vm.promotionalPriceTable.nome;
            } else {
                vm.onlineRetail.promotionalPriceTableId = null;
                vm.onlineRetail.promotionalPriceTableName = null;
            }
            if (vm.selectedBankAccount && vm.selectedBankAccount.codigo) {
                vm.onlineRetail.accountCode = vm.selectedBankAccount.codigo;
            }
            if (vm.selectedSeller && vm.selectedSeller.id) {
                vm.onlineRetail.sellerCode = vm.selectedSeller.id;
            }
            mapOnlineRetailProductSettings();

            ErpsSettingsService.updateERPConfiguration(vm.onlineRetail).then(
                function (response) {
                    if (!vm.onlineRetail.promotionalPriceTableId) {
                        FlyersService.clearFlyersDiscountedValue()
                            .then(function (resp) {
                                console.log(resp);
                            });
                    }
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.erps.online-retail.messages.success');
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        vm.createUpdateNexaas = function () {
            if (!vm.isSaving) {
                if (vm.nexaas.id) {
                    if (vm.nexaasStock.id !== vm.nexaas.stockId
                        || vm.nexaasSaleChannel.id !== vm.nexaas.saleChannelId
                        || vm.nexaasPriceTable.id !== vm.nexaas.priceTableId) {
                        openModalChangeNexaas();
                    } else {
                        updateNexaas();
                    }
                } else {
                    if ((vm.onlineRetail && vm.onlineRetail.id) || (vm.bling && vm.bling.id)) {
                        openModalChangePartner(createNexaas);
                    } else {
                        createNexaas();
                    }
                }
            }
        }

        function createNexaas() {
            vm.isSaving = true;
            vm.nexaas.storeId = vm.store.storeId;
            vm.nexaas.partnerId = Constants.ErpPartnerId.NEXAAS;
            vm.nexaas.entities = [vm.nexaasOrganization.id];
            vm.nexaas.stockId = vm.nexaasStock.id;
            vm.nexaas.saleChannelId = vm.nexaasSaleChannel.id;
            vm.nexaas.priceTableId = vm.nexaasPriceTable.id;
            vm.nexaas.priceTableName = vm.nexaasPriceTable.name;

            ErpsSettingsService.createERPConfiguration(vm.nexaas).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.erps.nexaas.messages.success');

                    ErpsSettingsService.startNexaasSynchronization(vm.store.storeId);

                    $state.reload();
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        function updateNexaas(synchronize) {
            vm.isSaving = true;
            vm.nexaas.storeId = vm.store.storeId;
            vm.nexaas.entities = [vm.nexaasOrganization.id];
            vm.nexaas.stockId = vm.nexaasStock.id;
            vm.nexaas.saleChannelId = vm.nexaasSaleChannel.id;
            vm.nexaas.priceTableId = vm.nexaasPriceTable.id;
            vm.nexaas.priceTableName = vm.nexaasPriceTable.name;

            ErpsSettingsService.updateERPConfiguration(vm.nexaas).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.erps.nexaas.messages.success');

                    if (synchronize) {
                        ErpsSettingsService.startNexaasSynchronization(vm.store.storeId);
                    }
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        vm.updateBling = function() {
            vm.isSaving = true;
            if (vm.bling.blingStoreId) {
                vm.bling.entities = [];
                vm.bling.entities.push(Number(vm.bling.blingStoreId));
            }

            mapBlingProductSettings();
            
            ErpsSettingsService.updateERPConfiguration(vm.bling).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.erps.bling.messages.success');
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        vm.createSoftcom = function () {
            if (!vm.isSaving) {
                ErpsSettingsService.createERPConfigurationForSoftcom(vm.store.storeId).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.softcom = response.data;
                    });
            }
        }

        function getSoftcomByStore(storeId) {
            ErpsSettingsService.getERPConfigurationByStoreIdAndPartnerId(storeId, Constants.ErpPartnerId.SOFTCOM).then(
                function (response) {
                    vm.isViewForm = true;
                    vm.softcom = response.data;
                });
        }

        function getOnlineRetailByStore(storeId) {
            ErpsSettingsService.getERPConfigurationByStoreIdAndPartnerId(storeId, Constants.ErpPartnerId.VAREJO_ONLINE).then(
                function (response) {
                    vm.isViewForm = true;
                    vm.onlineRetail = response.data;
                    if (vm.onlineRetail && vm.onlineRetail.id) {
                        vm.priceTable = {};
                        vm.priceTable.id = vm.onlineRetail.priceTableId;
                        vm.priceTable.nome = vm.onlineRetail.priceTableName;
                        vm.promotionalPriceTable = {};
                        vm.promotionalPriceTable.id = vm.onlineRetail.promotionalPriceTableId;
                        vm.promotionalPriceTable.nome = vm.onlineRetail.promotionalPriceTableName;
                        vm.entities = vm.onlineRetail.entities;
                        loadOnlineRetailProductSettings(response.data);
                        getEntitiesByToken();
                        vm.getOnlineRetailBankAccounts();
                        vm.getOnlineRetailSellers();
                    }
                });
        }

        function selectCategoryLevel(key) {
            if (key && vm.categoryLevelList) {
                var filtered = vm.categoryLevelList.filter(function(category) { return category.nome == key; });
                vm.selectedCategoryLevel = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        function selectBrandLevel(key) {
            if (key && vm.categoryLevelList) {
                var filtered = vm.categoryLevelList.filter(function(category) { return category.nome == key; });
                vm.selectedBrandLevel = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        function getNexaasByStore(storeId) {
            ErpsSettingsService.getERPConfigurationByStoreIdAndPartnerId(storeId, Constants.ErpPartnerId.NEXAAS).then(
                function (response) {
                    vm.isViewForm = true;
                    vm.nexaas = response.data;
                    if (vm.nexaas && vm.nexaas.id) {
                        vm.nexaasOrganization = vm.nexaas.entities.length ? vm.nexaas.entities[0] : null;
                        getOrganizationsByToken();
                    }
                });
        }

        function getBlingByStore(storeId) {
            ErpsSettingsService.getERPConfigurationByStoreIdAndPartnerId(storeId, Constants.ErpPartnerId.BLING).then(
                function (response) {
                    if (response && response.data) {
                        vm.isViewForm = true;
                        vm.bling = response.data;
                        loadBlingProductSettings(response.data);
                        if (vm.bling && vm.bling.entities && vm.bling.entities.length) {
                            vm.bling.blingStoreId = vm.bling.entities[0];
                        }
                    }
                });
        }

        vm.changePriceTable = function () {
            vm.promotionalPriceTableList = [{
                "nome": "Nenhum"
            }];

            var selectedPromotionalPriceTable = null;

            vm.priceTableList.filter(function (elem) { return elem.id != vm.priceTable.id })
                .forEach(function (elem) {
                    vm.promotionalPriceTableList.push(elem);
                });

            if (vm.promotionalPriceTable && vm.promotionalPriceTable.id && vm.priceTableList.length) {
                vm.promotionalPriceTableList
                    .forEach(function (elem) {
                        if (elem.id == vm.promotionalPriceTable.id) {
                            selectedPromotionalPriceTable = elem;
                        }
                    });
            }

            if (selectedPromotionalPriceTable) {
                vm.promotionalPriceTable = selectedPromotionalPriceTable;
            }
        }

        vm.getPriceTablesByToken = function () {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                vm.isLoadingPriceTable = true;
                var entities = vm.selectedEntity && vm.selectedEntity.id ? [vm.selectedEntity.id] : [];
                OnlineRetailService.getPriceTables(vm.onlineRetail.token, entities).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.isLoadingPriceTable = false;
                        vm.priceTableList = [];

                        if (response.data && response.data.length) {
                            vm.priceTableList = response.data.filter(function (elem) { return !elem.excluido });
                        }

                        if (vm.priceTable && vm.priceTable.id && vm.priceTableList.length) {
                            vm.priceTableList
                                .forEach(function (elem) {
                                    if (elem.id == vm.priceTable.id) {
                                        vm.priceTable = elem;
                                    }
                                });

                            vm.priceTableList.filter(function (elem) { return elem.id != vm.priceTable.id })
                                .forEach(function (elem) {
                                    vm.promotionalPriceTableList.push(elem);
                                });
                        }

                        if (vm.promotionalPriceTable && vm.promotionalPriceTable.id && vm.priceTableList.length) {
                            vm.promotionalPriceTableList
                                .forEach(function (elem) {
                                    if (elem.id == vm.promotionalPriceTable.id) {
                                        vm.promotionalPriceTable = elem;
                                    }
                                });
                        }
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.isLoadingPriceTable = false;
                        vm.success = null;
                        vm.error = err;
                        vm.priceTableList = [];
                        vm.promotionalPriceTableList = [];
                        processResponse();
                    });
            } else {
                vm.priceTable = null;
                vm.promotionalPriceTable = null;
                vm.isLoadingPriceTable = false;
            }
        }

        function getEntitiesByToken() {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                OnlineRetailService.getEntities(vm.onlineRetail.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.entitiesList = response.data;
                        mappingEntitiesToObject(vm.entitiesList);
                        vm.getPriceTablesByToken();
                        vm.getOnlineRetailBankAccounts();
                        vm.getOnlineRetailSellers();
                        vm.getOnlineRetailCategoryLevelList();
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.entitiesList = [];
                        processResponse();
                    });
            } else {
                vm.entities = [];
            }
        }

        function getOrganizationsByToken() {
            if (vm.nexaas && vm.nexaas.token && vm.nexaas.token != "") {
                ErpsSettingsService.getNexaasOrganizationsByToken(vm.nexaas.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.nexaasOrganizationList = response.data;
                        mappingOrganizationsToObject();
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.nexaasOrganizationList = [];
                        processResponse();
                    });
            } else {
                vm.nexaasOrganization = null;
            }
        }

        vm.getAllEntitiesByToken = function () {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                OnlineRetailService.getEntities(vm.onlineRetail.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.entitiesList = response.data;
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.entitiesList = [];
                        processResponse();
                    });
            } else {
                vm.entities = [];
            }
        }

        vm.getOnlineRetailBankAccounts = function () {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                var entityIds = vm.selectedEntity && vm.selectedEntity.id ? [vm.selectedEntity.id] : [];
                OnlineRetailService.getBankAccounts(vm.onlineRetail.token, entityIds).then(
                    function (response) {
                        vm.isViewForm = true;
                        if (response && response.data) {
                            vm.bankAccounts = response.data.filter(function(account) {
                                return account.tipoContaContabil === "SUBCONTA";
                            });
                            selectBankAcount(vm.onlineRetail.accountCode);
                        } else {
                            vm.bankAccounts = [];
                        }
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.bankAccounts = [];
                    });
            } else {
                vm.bankAccounts = [];
            }
        }

        vm.getOnlineRetailSellers = function () {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                OnlineRetailService.getSellers(vm.onlineRetail.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        if (response && response.data) {
                            vm.sellers = response.data.filter(function(seller) {
                                return seller.ativo && seller.permiteVender
                            });
                            selectSeller(vm.onlineRetail.sellerCode);
                        } else {
                            vm.sellers = [];
                        }
                        getShowkaseUsers();
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.sellers = [];
                        processResponse();
                    });
            } else {
                vm.sellers = [];
            }
        }

        vm.getOnlineRetailCategoryLevelList = function() {
            if (vm.onlineRetail && vm.onlineRetail.token && vm.onlineRetail.token != "") {
                OnlineRetailService.getCategoryLevelList(vm.onlineRetail.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        if (response && response.data) {
                            vm.categoryLevelList = response.data;
                            selectCategoryLevel(vm.onlineRetail ? vm.onlineRetail.baseCategoryKey : null);
                            selectBrandLevel(vm.onlineRetail ? vm.onlineRetail.baseBrandKey : null);
                        } else {
                            vm.categoryLevelList = [];
                        }
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        vm.categoryLevelList = [];
                    });
            } else {
                vm.categoryLevelList = [];
            }
        }

        function selectBankAcount(code) {
            if (code && vm.bankAccounts) {
                var filtered = vm.bankAccounts.filter(function(account) { return account.codigo == code; });
                vm.selectedBankAccount = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        function selectSeller(sellerId) {
            if (sellerId && vm.sellers) {
                var filtered = vm.sellers.filter(function(seller) { return seller.id == sellerId; });
                vm.selectedSeller = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        vm.getAllOrganizationsByToken = function () {
            if (vm.nexaas && vm.nexaas.token && vm.nexaas.token != "") {
                vm.isSaving = true;
                ErpsSettingsService.getNexaasOrganizationsByToken(vm.nexaas.token).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.nexaasOrganizationList = response.data;
                        vm.isSaving = false;
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;

                        vm.nexaasOrganization = null;
                        vm.nexaasOrganizationList = [];

                        vm.nexaasStock = null;
                        vm.nexaasSaleChannel = null;
                        vm.nexaasPriceTable = null;
                        processResponse();
                    });
            } else {
                vm.nexaasOrganization = null;
            }
        }

        vm.getNexaasOrganizationStocksByToken = function () {
            vm.nexaasStock = null;
            vm.nexaasStockList = [];
            vm.nexaasSaleChannel = null;
            vm.nexaasSaleChannelList = [];
            vm.nexaasPriceTable = null;
            vm.nexaasPriceTableList = [];

            if (vm.nexaas && vm.nexaas.token && vm.nexaas.token != "") {
                ErpsSettingsService.getNexaasOrganizationStocks(vm.nexaas.token, vm.nexaasOrganization.id).then(
                    function (response) {
                        vm.isViewForm = true;
                        vm.nexaasStockList = response.data;

                        if (vm.nexaas.stockId && vm.nexaasStockList.length) {
                            vm.nexaasStockList.forEach(function (stock) {
                                if (stock.id == vm.nexaas.stockId) {
                                    vm.nexaasStock = stock;
                                    vm.getNexaasSaleChannels();
                                }
                            })
                        }
                    },
                    function (err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = err;
                        processResponse();
                    });
            }
        }

        vm.getNexaasSaleChannels = function () {
            vm.nexaasSaleChannel = null;
            vm.nexaasSaleChannelList = [];
            vm.nexaasPriceTable = null;
            vm.nexaasPriceTableList = [];

            vm.nexaasStockList.forEach(function (stock) {
                if (vm.nexaasStock.id === stock.id) {
                    vm.nexaasSaleChannelList = stock.sale_channels;

                    if (vm.nexaas.saleChannelId && vm.nexaasSaleChannelList.length) {
                        vm.nexaasSaleChannelList.forEach(function (elem) {
                            if (elem.id == vm.nexaas.saleChannelId) {
                                vm.nexaasSaleChannel = elem;
                                vm.getNexaasPriceTables();
                            }
                        })
                    }
                }
            });
        }

        vm.getNexaasPriceTables = function () {
            vm.nexaasPriceTable = null;
            vm.nexaasPriceTableList = [];

            vm.nexaasSaleChannelList.forEach(function (saleChannel) {
                if (vm.nexaasSaleChannel.id === saleChannel.id) {
                    vm.nexaasPriceTableList = saleChannel.price_tables;

                    if (vm.nexaas.priceTableId && vm.nexaasPriceTableList.length) {
                        vm.nexaasPriceTableList.forEach(function (elem) {
                            if (elem.id == vm.nexaas.priceTableId) {
                                vm.nexaasPriceTable = elem;
                            }
                        })
                    }
                }
            });
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        vm.authBling = function() {
            if (vm.currentStore !== null && (vm.currentStore.cnpj === null || vm.currentStore.cnpj.length == 0)) {
                openCnpjModal();
            } else if ((vm.nexaas && vm.nexaas.id) || (vm.onlineRetail && vm.onlineRetail.id)) {
                openModalChangePartner(disconnectPartnerAndAuthorizeBling);
            } else {
                authorizeBling();
            }
        }

        vm.reconnectBling = function() {
            disconnectBling(authorizeBling);
        }

        vm.refreshBlingToken = function() {
            BlingService.refreshToken(vm.currentStore.id).then(
                function (response) {
                    if (response && response.data) {
                        vm.bling = response.data;
                    }
                    vm.loading = false;
                    $state.reload();
                }, function () {
                    vm.loading = false;
                    disconnectBling();
                    toastr.error($translate.instant('settings.erps.bling.messages.refresh-token-error'));
                }
            );
        }

        function openCnpjModal() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.bling.cnpjWarningTitle'),
                $translate.instant('settings.erps.bling.cnpjWarningQuestion'),
                null,
                $translate.instant('settings.erps.bling.cnpjButton'),
                $translate.instant('settings.erps.bling.no'),
                goToEditStoreScreen
            );
        }

        function goToEditStoreScreen() {
            $state.go('store-detail', { id: vm.currentStore.id, fromStep: true });
        }

        vm.openDisconnectBlingModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.bling.sureDisconnectTitle'),
                $translate.instant('settings.erps.bling.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.erps.bling.yes'),
                $translate.instant('settings.erps.bling.no'),
                disconnectBling
            );
        }

        vm.openDisconnectSoftcomModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.softcom.sureDisconnectTitle'),
                $translate.instant('settings.erps.softcom.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.erps.softcom.yes'),
                $translate.instant('settings.erps.softcom.no'),
                disconnectSoftcom
            );
        }

        vm.openDisconnectNexaasModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.nexaas.sureDisconnectTitle'),
                $translate.instant('settings.erps.nexaas.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.erps.nexaas.yes'),
                $translate.instant('settings.erps.nexaas.no'),
                disconnectNexaas
            );
        }

        vm.openDisconnectOnlineRetailModal = function() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.online-retail.sureDisconnectTitle'),
                $translate.instant('settings.erps.online-retail.sureDisconnectQuestion'),
                null,
                $translate.instant('settings.erps.online-retail.yes'),
                $translate.instant('settings.erps.online-retail.no'),
                disconnectOnlineRetail
            );
        }

        vm.openOnlineRetailOrderConfigModal = function () {
            vm.onlineRetailOrderConfigModal = $uibModal.open({
                templateUrl: 'app/admin/settings/erps/online-retail/modal/online-retail-order-config.html',
                controller: 'OnlineRetailOrderConfigController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    config: function () {
                        return vm.onlineRetail;
                    },
                    showkaseUsers: function() {
                        return vm.showkaseUsers;
                    },
                    onlineRetailUsers: function () {
                        return vm.sellers;
                    },
                    bankAccounts: function () {
                        return vm.bankAccounts;
                    }
                },
            }).result.then(function (response) {
                if (response.data) {
                    if (response.data.userLinksView) {
                        vm.userLinksView = response.data.userLinksView;
                    }
                    if (response.data.userLinks) {
                        vm.onlineRetail.userLinks = response.data.userLinks;
                    }
                    vm.onlineRetail.accountCode = response.data.accountCode;
                    vm.onlineRetail.sellerCode = response.data.sellerCode;
                    vm.selectedSeller = response.data.selectedSeller;
                    vm.selectedBankAccount = response.data.selectedBankAccount;
                }
                 
            });
        }

        vm.openOnlineRetailProductConfigModal = function () {
            vm.onlineRetailOrderConfigModal = $uibModal.open({
                templateUrl: 'app/admin/settings/erps/online-retail/modal/online-retail-product-config.html',
                controller: 'OnlineRetailProductConfigController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    config: function () {
                        return vm.onlineRetail;
                    },
                    categoryLevelList: function () {
                        return vm.categoryLevelList;
                    }
                },
            }).result.then(function (response) {
                if (response.data) {
                    loadOnlineRetailProductSettings(response.data);
                }
            });
        }

        function disconnectPartnerAndAuthorizeBling() {
            if (vm.nexaas && vm.nexaas.id) {
                disconnectNexaas(authorizeBling);
            } else if (vm.onlineRetail && vm.onlineRetail.id) {
                disconnectOnlineRetail(authorizeBling);
            }
        }

        function authorizeBling() {
            vm.loading = true;
            StoreService.authorizeBling().then(
                function(response) {},
                function(){ vm.loading = false; }
            );
        }

        function disconnectSoftcom() {
            vm.loading = true;
            ErpsSettingsService.deleteErpConfiguration(vm.softcom.id).then(
                function (response) {
                    if (response) {
                        vm.softcom = null;
                    }
                    vm.loading = false;
                    $state.reload();
                }, function () {
                    vm.loading = false;
                    toastr.error($translate.instant('settings.erps.softcom.messages.disconnect-partner-error'));
                }
            );
        }

        function disconnectBling(callback) {
            vm.loading = true;
            ErpsSettingsService.deleteErpConfiguration(vm.bling.id).then(
                function (response) {
                    if (response) {
                        vm.bling = null;
                        if (callback) {
                            callback();
                        }
                    }
                    vm.loading = false;
                    $state.reload();
                }, function () {
                    vm.loading = false;
                    toastr.error($translate.instant('settings.erps.bling.messages.disconnect-partner-error'));
                }
            );
        }

        function disconnectNexaas(callback) {
            vm.loading = true;
            ErpsSettingsService.deleteErpConfiguration(vm.nexaas.id).then(
                function (response) {
                    if (response) {
                        vm.nexaas = null;
                        if (callback) {
                            callback();
                        }
                    }
                    vm.loading = false;
                    $state.reload();
                }, function () {
                    vm.loading = false;
                    toastr.error($translate.instant('settings.erps.nexaas.messages.disconnect-partner-error'));
                }
            );
        }

        function disconnectOnlineRetail(callback) {
            vm.loading = true;
            ErpsSettingsService.deleteErpConfiguration(vm.onlineRetail.id).then(
                function (response) {
                    if (response) {
                        vm.onlineRetail = null;
                        if (callback) {
                            callback();
                        }
                    }
                    vm.loading = false;
                    $state.reload();
                }, function () {
                    vm.loading = false;
                    toastr.error($translate.instant('settings.erps.nexaas.messages.disconnect-partner-error'));
                }
            );
        }

        function mappingEntitiesToObject(voEntityList) {
            if (vm.entities && voEntityList) {
                vm.entities.forEach(function (entityId) {
                    voEntityList.forEach(function (entity) {
                        if (entityId === entity.id) {
                            vm.selectedEntity = entity;
                        }
                    })
                })
            }
        }

        function mappingOrganizationsToObject() {
            if (vm.nexaasOrganization && vm.nexaasOrganizationList.length) {
                vm.nexaasOrganizationList.forEach(function (organization) {
                    if (organization.id === vm.nexaasOrganization) {
                        vm.nexaasOrganization = { id: organization.id, name: organization.name };
                    }
                })
            }
        }

        function processResponse(message) {
            if (vm.error) {
                if (vm.error && vm.error.data && vm.error.data.headers.statuskey === 'cannotGetPriceTable') {
                    toastr.error($translate.instant('settings.erps.online-retail.messages.error-price-table'), toastr.options);
                } else if (vm.error && vm.error.data && vm.error.data.headers.statuskey === 'cannotGetEntities') {
                    toastr.error($translate.instant('settings.erps.online-retail.messages.error-entities'), toastr.options);
                } else if (vm.error && vm.error.data && vm.error.data.headers.statuskey === 'cannotGetOrganizationsOnNexaas') {
                    toastr.error($translate.instant('settings.erps.nexaas.messages.error-organizations'));
                } else if (vm.error && vm.error.data && vm.error.data.headers.statuskey === 'cannotGetBankAccounts') {
                    toastr.error($translate.instant('settings.erps.online-retail.messages.error-bank-accounts'), toastr.options);
                } else {
                    toastr.error($translate.instant('settings.store.messages.error.fail'));
                }
                vm.error = undefined;
            } else if (vm.success && vm.success == 'OK') {
                vm.isViewForm = false;
                vm.success = undefined;
                toastr.success($translate.instant(message));
            }
        }

        function openModalChangeNexaas() {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.nexaas.messages.sureChangeDataTitle'),
                $translate.instant('settings.erps.nexaas.messages.sureChangeDataQuestion'),
                null,
                $translate.instant('settings.erps.nexaas.yes'),
                $translate.instant('settings.erps.nexaas.no'),
                function () {
                    updateNexaas(true);
                }
            );
        }

        function openModalChangePartner(callback) {
            ModalFactory.openWarningModal(null,
                $translate.instant('settings.erps.nexaas.messages.sureChangeDataTitle'),
                $translate.instant('settings.erps.nexaas.messages.sureChangePartnerQuestion'),
                null,
                $translate.instant('settings.erps.nexaas.yes'),
                $translate.instant('settings.erps.nexaas.no'),
                function () {
                    callback();
                }
            );
        }

        function processBlingResponse() {
            var queryParams = $location.search();

            if (queryParams.error) {
                switch (queryParams.error) {
                    case 'BLING_NOT_SEND_CODE':
                        toastr.error($translate.instant('settings.erps.bling.error.BLING_NOT_SEND_CODE'));
                        break;
                    case 'BLING_NOT_SEND_TOKEN':
                        toastr.error($translate.instant('settings.erps.bling.error.BLING_NOT_SEND_TOKEN'));
                        break;
                    case 'BLING_CNPJ_INVALID':
                        toastr.error($translate.instant('settings.erps.bling.error.BLING_CNPJ_INVALID'));
                        break;
                    case 'BLING_CNPJ_DIFFERENT':
                        toastr.error($translate.instant('settings.erps.bling.error.BLING_CNPJ_DIFFERENT'));
                        break;
                    case 'UNAVAILABLE':
                        toastr.error($translate.instant('settings.erps.bling.error.UNAVAILABLE'));
                        break;
                    case 'NONE':
                        toastr.success($translate.instant('settings.erps.bling.successConnected'));
                        break;
                    default:
                        toastr.error($translate.instant('settings.erps.bling.error.UNAVAILABLE'));
                        break;
                }
            }
        }

        function getShowkaseUsers() {
            UserService.getUsersSellers({ storeId: $localStorage.defaultStore.storeId }).then(function (response) {
                vm.showkaseUsers = response.data;
                loadUserLinks();
            });
        }

        function loadUserLinks() {
            if (vm.onlineRetail && vm.onlineRetail.userLinks) {
                vm.userLinksView = vm.onlineRetail.userLinks.map(function(link) {
                    var shkUser = getUserById(link.userId, vm.showkaseUsers);
                    var orUser = getUserById(link.erpUserId, vm.sellers);
                    if (shkUser && orUser) {
                        return {
                            showkaseId: shkUser.id,
                            showkaseName: shkUser.fullName,
                            onlineRetailId: orUser.id,
                            onlineRetailName: orUser.nome
                        };
                    }

                    return null;
                }).filter(function(item) { return item !== null });
            }
        }

        function getUserById(id, entityList) {
            if (entityList) {
                var filtered = entityList.filter(function(item) { return item.id === id });
                return filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
            
            return undefined;
        }

        function mapOnlineRetailProductSettings() {
            var ignoredSyncParams = [];
            if (!vm.syncName) {
                ignoredSyncParams.push("SYNC_PARAM_NAME")
            }
            if (!vm.syncDescription) {
                ignoredSyncParams.push("SYNC_PARAM_DESCRIPTION")
            }
            if (!vm.syncImage) {
                ignoredSyncParams.push("SYNC_PARAM_IMAGE")
            }
            if (!vm.syncCategory) {
                ignoredSyncParams.push("SYNC_PARAM_CATEGORY")
            }
            if (!vm.syncBrand) {
                ignoredSyncParams.push("SYNC_PARAM_BRAND")
            }
            if (!vm.syncTags) {
                ignoredSyncParams.push("SYNC_PARAM_TAGS")
            }
            
            vm.onlineRetail.onlyEcommerce = vm.onlyEcommerce;
            vm.onlineRetail.ignoredSyncParams = ignoredSyncParams;
            vm.onlineRetail.baseCategoryKey = vm.selectedCategoryLevel ? vm.selectedCategoryLevel.nome : null;  
            vm.onlineRetail.baseBrandKey = vm.selectedBrandLevel ? vm.selectedBrandLevel.nome : null;  
        }

        function mapBlingProductSettings() {
            var ignoredSyncParams = [];
            ignoredSyncParams.push("SYNC_PARAM_IMAGE");
            ignoredSyncParams.push("SYNC_PARAM_TAGS");

            if (!vm.bling.syncName) {
                ignoredSyncParams.push("SYNC_PARAM_NAME")
            }
            if (!vm.bling.syncDescription) {
                ignoredSyncParams.push("SYNC_PARAM_DESCRIPTION")
            }
            if (!vm.bling.syncCategory) {
                ignoredSyncParams.push("SYNC_PARAM_CATEGORY")
            }
            if (!vm.bling.syncBrand) {
                ignoredSyncParams.push("SYNC_PARAM_BRAND")
            }
            vm.bling.ignoredSyncParams = ignoredSyncParams;
            vm.bling.transactionIdOnPartner = vm.bling.linkNumber ? "NUMBER" : "NUMBER_VIRTUAL_STORE";    
        }

        function loadOnlineRetailProductSettings(data) {
            vm.onlineRetail.ignoredSyncParams = data.ignoredSyncParams ? data.ignoredSyncParams : [];
            vm.onlineRetail.roundPrice = data.roundPrice;
            vm.onlineRetail.baseCategoryKey = data.baseCategoryKey;
            vm.onlineRetail.baseBrandKey = data.baseBrandKey;
            vm.syncName = !data.ignoredSyncParams.includes("SYNC_PARAM_NAME");
            vm.syncDescription = !data.ignoredSyncParams.includes("SYNC_PARAM_DESCRIPTION");
            vm.syncImage = !data.ignoredSyncParams.includes("SYNC_PARAM_IMAGE");
            vm.syncCategory = !data.ignoredSyncParams.includes("SYNC_PARAM_CATEGORY");
            vm.syncBrand = !data.ignoredSyncParams.includes("SYNC_PARAM_BRAND");
            vm.syncTags = !data.ignoredSyncParams.includes("SYNC_PARAM_TAGS");
            vm.roundPrice = data.roundPrice;
            vm.onlyEcommerce = data.onlyEcommerce;
            vm.inventoryThreshold = data.inventoryThreshold != null && data.inventoryThreshold != undefined;
        }

        function loadBlingProductSettings(data) {
            vm.bling.ignoredSyncParams = data.ignoredSyncParams ? data.ignoredSyncParams : [];
            vm.bling.syncName = !data.ignoredSyncParams.includes("SYNC_PARAM_NAME");
            vm.bling.syncDescription = !data.ignoredSyncParams.includes("SYNC_PARAM_DESCRIPTION");
            vm.bling.syncCategory = !data.ignoredSyncParams.includes("SYNC_PARAM_CATEGORY");
            vm.bling.syncBrand = !data.ignoredSyncParams.includes("SYNC_PARAM_BRAND");
            vm.bling.linkNumber = data.transactionIdOnPartner === "NUMBER";   
        }

    }
})();
