(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('PagarMeService', PagarMeService);

        PagarMeService.$inject = ['PagarMeFactory'];

    function PagarMeService(PagarMeFactory) {

        var service = {
            getRecipient: getRecipient,
            createRecipient: createRecipient,
            updateRecipient: updateRecipient,
            authorize: authorize,
            authenticate: authenticate
        };

        return service;

        function authorize(storeId) {
            return PagarMeFactory.authorize({storeId: storeId}).$promise;
        }

        function authenticate(storeId, authCode) {
            return PagarMeFactory.authenticate({storeId: storeId, authCode: authCode}).$promise;
        }

        function getRecipient(managerUserId) {
            return PagarMeFactory.getRecipient({managerUserId: managerUserId}).$promise;
        }

        function createRecipient(managerUserId, recipient) {
            return PagarMeFactory.createRecipient({managerUserId: managerUserId}, recipient).$promise;
        }

        function updateRecipient(id, recipient) {
            return PagarMeFactory.updateRecipient({id: id}, recipient).$promise;
        }
    }
})();
